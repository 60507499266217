@import "../../containers/colors";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #FD553F;
  z-index:101;
  display: flex;
  box-shadow: 0 1px 3px -1px rgba(51, 51, 51, 0.48);

  &--small {
    @media screen and (max-width: 840px) {
      height: 140px !important;
    }
  }

  &-help-container {
    @media screen and (min-width: 840px) {
      display: none;
    }
    &__title {
      color: #FFFFFF;
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &__question {
      color: #FFFFFF;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
  }

&__left {
    display: flex;
    width: 60px;
    justify-content: flex-end;
    align-items: center;

    &__logout {
      display: none;
    }
  }
  &__center {
    display: flex;
    width: calc(100% - 120px);
    justify-content: center;
    align-items: center;

    &__logo {
      width: 80%;
      max-width: 250px;
    }
  }
  &__right {
    display: flex;
    width: 60px;
    justify-content: flex-start;
    align-items: center;

    &__home {
      display: none;
    }
  }
}
.menu-icon {
  color: #FFFFFF;
  font-size: 30px;
}
.logoff-icon {
  color:#333;
  font-size: 25px;
}


.header {
  font-size: 16px;
  font-weight: 600;
  height: 170px;
  background: $red;
  z-index:101;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFFFFF;

  &--image {
    background: $red;
  }
  &__left-part{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    padding-top: 20px;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: -15px;

    &:focus,
    &:hover
    {
      outline: none;
      color: #FFFFFF;
    }

    @media screen and (max-width: 320px) {
      font-size: 18px;
      width: 30px;
      height: 30px;
      margin-top: 6px;
    }
  }
  &__center-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    &__avatar {
      margin-top: 10px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 42px;
      }
    }
    &__name {
      margin-top: 5px;
      font-weight: bold;
      font-size: 20px;
    }
    &__flex {
      display: flex;
      gap: 8px;
      font-weight: 400;

      svg {
        margin-left: 5px;
      }
    }
    &__icon {
      color: #FFFFFF;

      &:focus,
      &:hover {
        outline: none;
        color: #FFFFFF;
        text-decoration: underline;
      }
    }
    &__logo {
      img {
        width: 100%;
        max-width: 180px;
        margin-top: 12px;
      }

      &--help {
        img {
          width: 100%;
          max-width: 150px;
          margin-top: 15px;
        }
      }
    }

  }
  &__right-part{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    padding-top: 20px;
    cursor: pointer;
    color: #FFFFFF;
    margin-right: -15px;
    &:hover {
      color: #FFFFFF;
    }

    @media screen and (max-width: 320px) {
      font-size: 18px;
      width: 30px;
      height: 30px;
      margin-top: 6px;
    }
  }
  &__left {
    padding-left: 15px;
    width: auto;
    color: #000;
    &__logout {
      padding-left: 15px;
      display: flex;
    }
  }
  &__right {
    padding-right: 15px;
    min-width: 200px;
    color: $red;
    display: flex;
    justify-content: flex-end;
    &__home {
      padding-right: 15px;
      display: flex;
    }
  }
}

// Bigger than mobile
@media screen and (min-width: 840px) {
  .header {
    font-size: 16px;
    font-weight: 600;
    height: 210px;
    background: $red;
    z-index:101;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 20px;
    color: #FFFFFF;

    &--image {
      background: url("../../image/poings.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 30% 50%;
    }
    &__left-part{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      padding-top: 0;
      
    }
    &__center-part{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: -10px;

      &__avatar {
        img {
          width: 57px;
          height: 57px;
          border-radius: 57px;
        }
      }
      &__name {
        margin-top: 10px;
        font-weight: bold;
        font-size: 20px;
      }
      &__logout {
        font-weight: 400;
      }
      &__logo {
        img {
          width: 100%;
          max-width: 220px;
        }
        &--help {
          img {
            margin-top: 50px;
            width: 100%;
            max-width: 500px;
          }
        }
      }

    }
    &__right-part{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      padding-top: 0;

    }
    &__left {
      padding-left: 15px;
      width: auto;
      color: #000;
      &__logout {
        padding-left: 15px;
        display: flex;
      }
    }
    &__right {
      padding-right: 15px;
      min-width: 200px;
      color: $red;
      display: flex;
      justify-content: flex-end;
      &__home {
        padding-right: 15px;
        display: flex;
      }
    }
  }
}