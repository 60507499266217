.dashboard {

  &__modale {
    width: 100%;
    display: flex;

    &__mail {
      font-style: italic;
      display: flex;
      justify-content: space-between;

      &__label {
        margin-left: 10px;
      }

      &__input {
        border: none;
        font-style: italic;
        width: 30%;
        transition: all .3s ease-in-out;
        &--edit {
          transition: all .3s ease-in-out;
          color: #ed5e4f;
          border: solid 1px #ed5e4f;
          font-weight: bold;
          padding-left: 5px;
        }
      }
    }

    &__name {
      font-style: italic;
      display: flex;
      justify-content: space-between;

      &__label {
        margin-left: 10px;
      }

      &__input {
        border: none;
        font-style: italic;
        margin-right: 10px;
        transition: all .3s ease-in-out;

        &--edit {
          transition: all .3s ease-in-out;
          color: #ed5e4f;
          border: solid 1px #ed5e4f;
          font-weight: bold;
          padding-left: 5px;
        }
      }
    }

    &__connexion {
      font-size: 13px;
      font-weight: 600;
    }
    &__row {
      border-bottom: solid 1px #e6e6e6;
      display: flex;
      width: 100%;
      min-height: 35px;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;

      &:last-child {
        border: none;
      }
    }

    &__mfc {
      border: solid 1px #e6e6e6;
      border-radius: 2px;
      margin-top: 15px;

      &__bonus {
        margin-right: 30px;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__left {
      width: 100%;

      span {
        font-weight: bold;
        margin-left: 5px;
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      width: 100%
    }
  }
  &__page-info {
    margin-bottom: 15px;
    font-weight: 600;
  }
  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
      background: #4b546a;
      display: flex;
      flex-direction: row;
      width: 100%;
      color: #FFF;
      height: 60px;
      align-items: center;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      &__item {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0;
        align-items: center;
        padding-left: 15px;
        border-right: solid 1px #FFF;
        font-weight: 600;
        font-size: 18px;

        &:last-child {
          border: none;
        }
      }
    }
    &__body {
      width: 100%;

      &__empty {
        display: flex;
        justify-content: center;
        height: 80px;
        align-items: center;
        font-weight: bold;
        padding-top: 10px;
        font-size: 19px;
      }
      &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
        min-height: 45px;
        align-items: center;

        @media screen and (max-width: 550px) {
          flex-direction: column;
        }
        &:nth-child(odd) {
          background: #eaeaea;
        }

        &__col {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 5px;
          padding-left: 15px;
          align-items: center;
          min-height: 45px;
          text-align: left;

          span {
            font-size: 12px;
          }

          &--bold {
            font-weight: 600;
          }

          &__avatar {
            height: 48px;
            width: 48px;
            min-width: 48px;
            border: solid 1px #525355;
            border-radius: 48px;

            img {
              height: 100%;
              width: 100%;
              overflow: hidden;
              border-radius: 48px;
            }
          }
        }
      }
    }
  }
  &__pagination {
    border-top: solid 1px #333;
    margin-top: 20px;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.pastille__container {
  &__row {
    display: flex;
    width: 100%;
    align-items: center;

    &--ok {
      display: flex;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background: rgb(60, 216, 60);
      margin-right: 5px;
      &:after {
        content: ''
      }
    }
    &--ko {
      display: flex;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background: rgb(254, 81, 70);
      margin-right: 5px;
      &:after {
        content: ''
      }
    }
  }
}
