.mfc-stats {
  padding: 30px;
  height: 100%;

  &__menu {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  pre, code {
    white-space: pre-line;
  }
}
