@import '../../containers/colors';

.mfc-title {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  background: $howGray;
}