@import "../../containers/colors";

.breadcrumb {
  position: fixed;
  top: 60px;
  background: $red;
  color:#FFF;
  left: 0;
  width: 100%;
  height: 165px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 600;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  &__avatar {
    height: 50px;
    width: 50px;

    img {
      border-radius: 50px;
      height: 100%;
      width: 100%;
    }
  }

  &__user {
    color: #FFFFFF;
    font-size: 22px;
    margin-top: 5px;
  }

  &__help {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 100;
  }

  &__icon {
    font-size: 20px;
    color: #FFFFFF;

    &:focus {
      outline: none;
      color: #FFFFFF;
    }
  }
  &__iconz {
    padding-right: 10px;
  }

  &__title {
    font-size: 18px;
  }
  &__italic {
    padding-left: 10px;
    font-size: 18px;
    font-style: italic;
  }

  &__desktop {
    display: none;
  }
}

// Bigger than mobile
@media screen and (min-width: 840px) {
  .breadcrumb {
    display: none;

    &__title {
      font-size: 24px;
    }

    &__desktop {
      display: flex;
      margin-top: 25px;
      margin-bottom: 10px;

      &__item {
        display: flex;

        &__container {
          color: #000000;
          border-radius: 6px;
          display: flex;
          min-height: 28px;
          justify-content: center;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 600;
          padding-bottom: 2px;

          &__icon {
            width: 30px;
          }
          &__label {
            display: flex;
            align-items: center;
          }

            // background: rgba(255, 92, 92, 0.20);
            color: #000000;
            font-weight: 200;
            text-decoration: underline;
            text-decoration-color: rgba(0, 0, 0, .2);

          &--last {
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 2px;
            text-decoration: none;
          }
        }

        &:after {
          content: '>';
          display: flex;
          width: 30px;
          justify-content: center;
          align-items: center;
          color: rgba(0, 0, 0, .2);
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

      }
    }
  }
  .breadcrumb__line {
    display: flex;
    border-bottom: solid 1px #e6e6e6;
  }
}