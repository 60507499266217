@import '../../containers/colors';

.omg-navigation {
  background: yellow;
}
.omg--spec {
  background: red;
  padding-top: 0!important;
  padding-bottom: 0!important;
  height: 60px;
  .mfc__left__content {
    // display: flex;
    height: 60px;
  }
}
.mfc__left--hidden {
  @media (max-width: 840px) {
    display: none;
  }
}
.videoContainer {
  padding-bottom: 80px;
}

iframe {
  height: 200px;
}
.mfc-replay {


  height: 100%;
  &--replay {
    // padding-top: 60px;
    @media (min-width: 840px) {
      //padding-top: 20px;
    }
  }
  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #000;
    font-weight: 500;

    &__pack-info {
      margin-bottom: 30px;
      padding-right: 10px;
      text-decoration: underline;

      a {
        color: $red;
        &:focus,
        &:hover {
          color: $red;
        }
      }

    }
  }
  &__week {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      margin-bottom: 15px;

      svg {
        color: $red;
      }
    }
    &__hiits {
      display: flex;
      width: calc(100% + 16px);
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: auto;
      margin-left: -8px;

      &:after {
        // content: "";
        // flex: auto;
      }

      &--w1 {
        background-color: #ff5146;
      }
      &--w2 {
        background-color: #ff6446;
      }
      &--w3 {
        background-color: #ff7d49;
      }
      &--w4 {
        background-color: #ff9249;
      }
      &--w5 {
        background-color: #ffb049;
      }
      &--w6 {
        background-color: #ff9249;
      }
      &--w7 {
        background-color: #ff7d49;
      }
      &--w8 {
        background-color: #ff6446;
      }
      &--w9 {
        background-color: #ff5146;
      }


      &__scroll {
        display: flex;
        width: 100%;
        flex-direction: column;
      }

      &__box,
      a{
        // min-width: calc((100% / 2) - 4px);
        // height: 100%;
        width: 150px;
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 8px;
        margin-left: 8px;
        margin-bottom: 20px;

        &__container {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-style: italic;
          font-size: 20px;
          font-weight: 600;
          &__left {
            display: flex;
            align-items: flex-end;
            width: 40px;
            height: 100%;
          }

          &__right {
            display: flex;
            align-items: flex-end;
            width: 40px;
            height: 100%;

            img {
              width: 40px;
              padding-right: 10px;
              padding-bottom: 10px;
            }
          }

          &__nutri {
            background: url("../../image/nutri-bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;

            &__icon {
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              img {
                height: 60px;
              }
            }
          }
        }
        &--locked {
          opacity: .4;
        }
      }
    }
  }
}
.forbidden-box {
  cursor: not-allowed;
}
.tabs-container {
  display: flex;
  width: 100%;
  min-height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  top: 125px;
  z-index: 2;
  padding-bottom: 5px;
  background: #FFF;

  a.tabs-container__tab {
    color: $gray;
    border: 0;
    text-transform: uppercase;
  }
  a.tabs-container__tab--selected {
    color: $red;
  }
  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 100%;
    background: $tabgray;
    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    text-align: center;
    &:focus,
    &:active {
      outline:0;
    }

    &--selected {
      background: #FFF;
      //border: solid 1px $gray;
      border-bottom: none;
      color: $red;
    }
  }
}

.is-mobile-tabs {
  // margin-top: 90px;
  width: 100%;
  height: 100%;
  // min-height: 800px;
  // padding-right: 30px;
  @media (max-width: 840px) {
    // margin-top: 0;
  }
}

@media (min-width: 840px) {
  .mfc-title-desktop {
    //display: none;
  }
  .videoContainer {
    //padding-bottom: 0;
  }

  .is-mobile-tabs {
    margin-top: 0;
    padding-right: 0;
  }
  .mfc-replay {
    &__week {
      &__hiits {
        &__box,
        a{
          //min-width: calc(100% / 3.15);
          //height: 150px;

          &--locked {
            opacity: .4;
          }
        }
      }
    }
  }
}
.mfc-title-desktop {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  padding-bottom: 40px;
}
.flex-grey-tabs {
  display: flex;
  width: 100%;
}

iframe {
  .ytp-chrome-top-buttons {
    display: none!important;
    visibility: hidden!important;
  }
}