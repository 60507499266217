.promocode {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__left {
    display: flex;

    &__input {
      display: flex;
      flex-direction: column;
      width: 300px;
    }
    &__button {
      margin-left: 30px;
      width: 250px;
    }
  }
  Button {
    margin-bottom: 30px;
    font-weight: bold;
  }

  &__container {
    padding-top: 30px;
    margin-top: 30px;
    background: #FFF;
  }

  &__generate {
    margin-top: 30px;
    display: flex;

    &__result {
      background: #FFF;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      height: 34px;
      padding-right: 10px;
      border: solid 1px #dcdcdc;
      margin-left: 15px;
    }
  }
}
.redify {
  color: #ed5e4f;
}
.greenify {
  color: #4caf50;
}
b {
  margin-right: 5px;
}
.button--green {
  background-color: #10a500!important;
  color: #FFF!important;
}
.button--red {
  background-color: #ed5e4f!important;
  color: #FFF!important;
}