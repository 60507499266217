@import "../../containers/colors";

.help-container {
  &__back-to-top {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: $red;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .3;
    transition: all .1s ease-in-out;
    cursor: pointer;
    font-size: 20px;

    &:hover {
      opacity: 1;
      transition: all .1s ease-in-out;
    }

    @media screen and (max-width: 840px) {
      right: 20px;
      bottom: 20px;
      height: 40px;
      width: 40px;
      border-radius: 40px;
    }

  }
  @media screen and (max-width: 840px) {
    margin-top: -90px;
  }

  &--hide {
    @media screen and (max-width: 840px) {
      display: none;
    }
    margin-top: 20px;
  }
  &__home {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 40px!important;
    color: #838383;

    &:focus,
    &:hover {
      color: #838383;
    }

  }
  &__title {
    font-size: 46px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000000;
    width: 100%;
    text-align: center;
  }
  &__question {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    width: 100%;
    text-align: center;
  }
  &__boxes {
    width: 100%;
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    &__box {
      width: 100%;
      max-width: calc((100% / 3) - 30px);
      min-width: 200px;
      background: #FFE4E0;
      color: $red;
      border-radius: 10px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all .1s ease-in-out;
      cursor: pointer;

      @media screen and (max-width: 840px) {
        width: calc(100% - 30px);;
        min-width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
      }

      &:hover {
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
        transition: all .1s ease-in-out;
      }

      &__question {
        color: $red;
        font-size: 18px;
        line-height: 150%;
        font-weight: 600;
      }
      &__action {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &__button {
          background: $red;
          color: #FFFFFF;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          height: 30px;
          border-radius: 30px;
          padding-left: 15px;
          padding-right: 15px;
          cursor: pointer;
          transition: all .1s ease-in-out;

          &:hover {
            transform: scale(1.05);
            transition: all .1s ease-in-out;
          }
        }
      }
    }
  }
  &__section {
    color: #000000;
    width: 100%;
    margin-top: 30px;
    padding-left: 15px;
    &__title {
      font-size: 24px;
      font-weight: bolder;
      margin-bottom: 15px;
    }
    &__text {
      margin-bottom: 30px;
      font-size: 16px;
      width: 100%;
      &__browsers {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;

        &__browser {
          margin-top: 30px;
          width: 100%;
          max-width: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          span {
            font-weight: bold;
            font-size: 18px;
            margin-top: 10px;
          }
          img {
            width: 100%;
            max-width: 150px;
            transition: all .1s ease-in-out;

            &:hover {
              transform: scale(1.05);
              transition: all .1s ease-in-out;
            }
          }
        }
        @media screen and (max-width: 840px) {
          justify-content: center;
          &__browser {
            width: 100%;
            max-width: 50%;
            justify-content: space-between;
            img {
              width: 100%;
              max-width: 100px;
              transition: all .1s ease-in-out;

              &:hover {
                transform: scale(1.05);
                transition: all .1s ease-in-out;
              }
            }
          }
        }
      }
      &__captures {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;

        &__capture {
          margin-top: 30px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            font-weight: bold;
            font-size: 18px;
            margin-top: 10px;
          }
          img {
            width: 100%;
            max-width: 600px;
          }
        }
      }
      &__puce {
        display: flex;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
        &__left {
          width: 40px;
        }
        &__right {
          width: calc(100% - 40px);

          span {
            color: $red;
            font-weight: bolder;
          }

          &--link {
            span {
              text-decoration: underline;
              color: $red;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}