@import "../../containers/colors";

.loader-box {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 30px;

  &--no-margin {
    margin: 0;
  }

  &__icon {
    font-size: 60px;
    justify-content: center;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $greenInfo;
  }
  &__message {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 22px;
  }
  &__back {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    button {
      background: $green;
      border: solid 1px $green;

      &:hover {
        background: $greenDarker;
        border: solid 1px $greenDarker;
      }
    }
  }
}