$red: #FD553F;
$redDark: #ea4338;
$blue: #2d2c3a;
$darkgray: #1b1b23;
$lightgray: #969696;
$gray: #6e6e6e;
$tabgray: #efefef;
$gray-light:#929292;
$green:#76a933;
$greenDarker:#5a8620;
$greenInfo:#31a031;
$extrraLightGray: #f9f9f9;
$howGray: #f1f1f1;
$orange: #ff7245;
$bgGray: #e7e7e7;