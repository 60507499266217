@import "../../containers/colors";

.complements-part {
  &__disclaimer {
    font-style: italic;
    padding-top: 10px;
  }
  &__head {
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 20px;
    color: #000;
    font-weight: bold;

    &__back {
      color: $red;
      font-size: 15px;
      cursor: pointer;
      text-decoration: underline;
      padding-left: 30px;
      font-weight: bold;

      svg {
        padding-right: 3px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &__row {
    padding-bottom: 10px;

    background: #f1f1f1;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    flex-direction: column;

    &--mp {
      flex-direction: row;
    }


    &__link {
      color: $red;
      font-size: 22px;
      padding: 5px;

      &--black {
        color: #000000;
      }
    }

    &--separator {
      height: 8px;
      width: 150px;
      background: $red;
      border-radius: 8px;
      margin: 30px auto;

    }

    span {
      font-weight: bold;
    }

    svg {
      margin-right: 5px;
      font-size: 16px;
      color: #00A9B7;
    }

    a {
      color: $red;
      font-weight: 600;
    }

    &__sign {
      font-weight: bold;
      text-align: right;
      margin-top: 15px;
      background: #FFFFFF;
    }

    &__img {
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: auto;
        max-width: 300px;
      }
    }
  }
}
.red-halteres {
  color: $red;
  margin-right: 5px;
}
.center {
  text-align: center;
  align-items: center;
  width: 100%;
  text-decoration: underline;
}
.inline-flex {
  background: #FFFFFF;
  display: flex;
  flex-direction: column!important;
  margin-bottom: 15px;
  padding: 5px;

  .complements-part__row {
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
  }
  a {
    color: $red;
    font-weight: bold;
  }
}
