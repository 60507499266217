@import "../../containers/colors";

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 0;
  }
}
.mfc {
  &__close {
    display: flex;
    min-height: 50px;
    align-items: center;
    background: rgb(242, 242, 242);
    margin-bottom: 16px;
    border: solid 3px $red;
    border-radius: 10px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 380px;
    width: 100%;

    @media screen and (min-width: 839px) {
      margin-left: 20px;
    }

    &__icon {
      color: #ed5d4f;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 20%;
      min-width: 40px;
      font-size: 24px;
      max-width: 60px;
    }
    &__text {
      display: block;
      width: 80%;

      a {
        color: $red;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  &__row {
    min-height: 150px;
    &--paid {
      border: solid 1px $red;
      margin-bottom: 30px;
      transition: all 300ms ease-in-out;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 30px 11px rgba(0, 0, 0, 0.15);
        margin-left: 15px;
        transition: all 300ms ease-in-out;
      }

      &__container {
        min-height: 0;
        padding: 20px;
        padding-top: 10px;
        padding-bottom: 15px;
        display: flex;
        width: 100%;

        &__right {
          width: 100%;
          &__content {
            &__top {
              &__label {
                display: flex;
                align-items: center;
                color: $red;
                font-weight: bold;
                font-size: 22px;

                span {
                  color: #333;
                  font-style: italic;
                  font-size: 14px;
                  padding-left: 5px;
                }
              }
            }
          }
        }
        &__icon {
          display: flex;
          width: 40px;
          align-items: center;
          justify-content: center;
          font-size: 26px;
        }
      }

    }
    &__join {
      background: url("../../image/fitcouple-blur-dark.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      color: #FFF!important;

      .mfc__row__container__right__bottom__text {
        font-size: 16px;
      }
    }
    &__large {
      display: flex;
      background: url("../../image/sunlights2.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      flex-direction: column;
      height: 100%;
      max-height: 300px;
      color: #FFF;
      padding: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      transition: all 300ms ease-in-out;
      justify-content: space-between;

      &:hover {
        box-shadow: 0 0 30px 11px rgba(0, 0, 0, 0.15);
        margin-left: 10px;
        transition: all 300ms ease-in-out;
      }

      &__container {
        display: flex;
        width: 100%;
        height: 100%;
        &__left {
          width: 100%;
          height: 100%;
          &__date {
            display: flex;
            align-items: center;
            font-size: 16px;
            min-height: 20px;
            height: 10%;
          }
          &__title {
            font-size: 26px;
            font-weight: bold;
            height: 20%;
            min-height: 40px;
            padding-top: 30px;
            color: #000000;
          }
          &__text {
            height: 35%;
            font-size: 15px;
            padding-right: 15px;
            span {
              font-weight: 600;
            }
          }
          &__action {
            height: 35%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 5px;

            &__button {
              background: $green;
              padding-left: 10px;
              padding-right: 10px;
              padding-top: 5px;
              padding-bottom: 5px;
              font-weight: bold;
              transition: background 300ms ease-in-out;

              svg {
                margin-left: 10px;
              }

              &:hover {
                background: $greenDarker;
                transition: background 300ms ease-in-out;
              }
            }
          }
        }
        &__right {
          display: flex;
          align-items: flex-start;
          padding-top: 20px;
          width: 30%;
          height: 100%;

          &__circle {
            background-color: $red;
            height: 70px;
            width: 70px;
            border-radius: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            transform: rotate(-10deg);
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 839px) and (max-width: 1000px) {
  .mfc {
    &__row {
      &__large {
        display: flex;
        background: url("../../image/sunlights2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        color: #FFF;
        padding: 20px;

        &__container {
          display: flex;
          width: 100%;
          &__left {
            width: 100%;
            &__date {
              font-size: 16px;
              min-height: 30px;
              height: 10%;
            }
            &__title {
              font-size: 22px;
              font-weight: bold;
              height: 20%;
              min-height: 50px;
            }
            &__text {
              height: 35%;
              font-size: 14px;
              padding-right: 15px;
              span {
                font-weight: 600;
              }
            }
            &__action {
              height: 35%;
              display: flex;
              align-items: flex-end;

              &__button {
                background: $green;
                padding-left: 13px;
                padding-right: 13px;
                padding-top: 7px;
                padding-bottom: 7px;
                font-weight: bold;
              }
            }
          }
          &__right {
            display: flex;
            align-items: flex-start;
            padding-top: 20px;
            width: 40%;

            &__circle {
              background-color: $red;
              height: 100px;
              width: 100px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              transform: rotate(-10deg);
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .mfc {
    &__row {
      transition: all 300ms ease-in-out;

      &:hover {
        box-shadow: 0 0 30px 11px rgba(0, 0, 0, 0.05);
        margin-left: 10px;
        transition: all 300ms ease-in-out;
      }
      &__large {
        display: flex;
        background: url("../../image/sunlights2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        color: #FFF;
        padding: 20px;

        &__container {
          display: flex;
          width: 100%;

          &__left {
            width: 100%;
            &__date {
              font-size: 20px;
              min-height: 20px;
              height: 10%;
            }
            &__title {
              font-size: 28px;
              font-weight: bold;
              height: 16%;
              min-height: 35px;
            }
            &__text {
              height: 35%;
              font-size: 16px;
              padding-right: 15px;
              span {
                font-weight: 600;
              }
            }
            &__action {
              height: 35%;
              display: flex;
              align-items: flex-end;

              &__button {
                background: $green;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                font-weight: bold;
              }
            }
          }
          &__right {
            display: flex;
            align-items: flex-start;
            padding-top: 20px;
            width: 40%;

            &__circle {
              background-color: $red;
              height: 100px;
              width: 100px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              transform: rotate(-10deg);
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 361px) {
  .mfc {
    &__row {
      &__large {
        &__container {
          &__left {
            &__action {
              &__button {
                background: $green;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 13px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.new__mfc__row {
  min-height: 120px;
  border-radius: 12px;
  background: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  margin-bottom: 20px;
  width: 100%;
  max-width: 380px;
  min-width: 300px;
  cursor: pointer;
  transition: all .1s ease-in-out;

  &__highlight {
    height: 240px;
    border-radius: 15px;
      &__image {
        background: url("../../image/mfc55flex.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        width: 50%;
        min-width: 40%;
        height: 100%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        &__dots {
          height: calc(100% - 20px);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          &__dot {
            height: 30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            padding: 7px;
            img {
              width: 100%;
              max-width: 20px;
            }
          }
        }
      }
    &__right {
      width: 50%;
      background: darkorange;
      height: 100%;
      padding: 15px;
      font-style: italic;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      &__name {
        font-size: 19px;
        font-weight: bold;
      }
      &__action {
        color: #66320f !important;
        font-style: normal;
        font-weight: bold!important;
        padding: 6px 10px!important;
        width: 100% !important;
        @media screen and (min-width: 840px) {
          padding: 6px 20px!important;
        }

        @media screen and (max-width: 375px) {
          font-size: 11px!important;
          padding: 6px!important;
          text-align: center;
        }
      }
    }
  }

  &:hover {
    box-shadow: 2px 2px 10px 0 rgba(165, 165, 165, .45);
    transform: scale(1.02);
    transition: all .3s ease-in-out;
  }

  @media screen and (min-width: 840px) {
    margin-left: 20px;
    margin-right: 20px;
  }


  &__reverse {
    background: #FFEEEC;
    color: $red;
  }


  &__image {
    width: 130px;
    height: 100px;
    padding-left: 15px;
    padding-right: 15px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 60px;
    }

    @media screen and (max-width: 320px) {
      width: 80px;
      height: 80px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 0;
    }
  }
  &__right {
    width: calc(100% - 130px);
    display: flex;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 320px) {
      width: calc(100% - 110px);
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__prices {
      display: flex;
      align-items: center;
      width: 100%;
      color: #FFFFFF;
      margin-top: 10px;
      margin-bottom: 15px;
      &__old {
        font-size: 20px;
        margin-right: 14px;

        @media screen and (max-width: 360px) {
          font-size: 18px;
        }
        &:after {
          content: '';
          height: 3px;
          width: 45px;
          margin-left: -42px;
          margin-top: 14px;
          background: $red;
          position: absolute;
          opacity: .8;
          transform: rotate(-34deg);
        }
      }
      &__new {
        font-size: 22px;
        font-weight: bold;

        @media screen and (max-width: 360px) {
          font-size: 20px;
        }
      }
    }

    &__name {
      font-size: 14px;
      font-weight: bold;
    }
    &__version {
      font-style: italic;
      font-size: 16px;
      padding-bottom: 10px;
    }
    &__level {
      margin-top: -10px;
      padding-bottom: 10px;
      font-weight: 600;
    }
    &__action {
      margin-bottom: 5px;
      background: #FFFFFF;
      border-radius: 20px;
      color: $red;
      padding: 6px 20px;
      text-transform: uppercase;
      align-self: flex-start;
      font-weight: bold;
      font-size: 12px;

      &__reverse {
        background: $red;
        color:#FFFFFF;
      }

      @media screen and (max-width: 320px) {
        padding: 6px 14px;
      }
    }
  }
}
