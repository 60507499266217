@import "../../containers/colors";

.modale-box {
  width: 100%;
  min-height: 300px;
  margin: auto;
  display: flex;
  box-shadow: 1px 1px 10px -5px #000;
  flex-direction: column;

  &--no-shadow {
    box-shadow: none;
  }

  &--no-margin {
    margin: 0;
    margin-top: 20px;
  }

  &__icon {
    font-size: 60px;
    justify-content: center;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $greenInfo;

    &--error {
      color: $red;
    }
  }
  &__message {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 22px;
    padding: 15px;
    text-align: center;
  }
  &__back {
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;

    button {
      background: $greenInfo;
      border: solid 1px $greenInfo;
      font-weight: 600;

      &:hover {
        background: $greenDarker;
        border: solid 1px $greenDarker;
      }
    }
  }
}