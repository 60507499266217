@import "../../../containers/colors";

.replay-navigation {
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  background: #FFF;
  margin-top: 40px;
  color: $red;

  &__icon {
    width: 40px;
    max-width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 40px;
    color: $red;
    background: #FFE4E0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      font-size: 18px;
      color: #000000;
    }
  }
  @media (max-width: 840px) {
    margin-top: 0px;
  }

  &--desktop {
    height: 85px;
    margin-bottom: 20px;

    // border: solid 1px #dedede;
  }

  &--disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &__left {
    width: 50px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: $red;
  }
  &__center {
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    font-size: 24px;
    height: 40px;
    font-weight: bold;
    align-items: center;
    color: #000000;

    &--mobile {
      background: #FFE4E0;
      color: $red;
      border-radius: 40px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }

    &__action {

    }

    &--full {
      width: 100%;
    }
  }
  &__right {
    width: 50px;
    display: flex;
    color: $red;
    font-size: 20px;
    justify-content: center;
  }
}