@import "../../containers/colors";
.facebook-advertise {
  &__home {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  color: #545454;
  padding: 20px;
  @media screen and (min-width: 760px) {
    padding-left: 0;
  }
  a {
    font-weight: bold;
    color: #415ba8;
    text-decoration: underline;
    font-size: 16px;
  }
    font-weight: bold;
}

.facebook-ellipsis {
  @media screen and (max-width: 330px) {
    font-size: 13px;
  }
}
.home-background {
  position: absolute;
  width: 100%;
  height: 100%;

  &__blue {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width:150px;
    height: 220px;
    background: $blue;
    z-index: 2;
  }
  &__red {
    position: absolute;
    width: 40%;
    background: $red;
    height: 100%;
    z-index: 1;
  }
}

.home-container {
  position: absolute;
  top:0;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 100%;

  &--isMobile {
    display: none;
  }
  &--isNoteMobile {
    display: flex;
  }

  &__logo {
    display: none;
  }

  &__img {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    img{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 800px;
    }
  }
  &__connect {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 40%;
    padding-right: 20px;
    flex-direction: column;

    @media screen and (max-width: 760px) {
      padding-right: 0;
    }

    &__large {
     width: 100%;

      &__not-me {
        padding-top: 10px;
        width: 100%;
        a {
          position: relative;
          width: 100%;
        }
      }

      &__title {
        font-size: 13px;
        color: $darkgray;
        width: 100%;
        margin-bottom: 5px;
      }
      &__logo {
        width: 100%;
        img {
          width: 100%;
          max-width: 300px;
        }

      }
      &__sub-title {
        font-size: 12px;
        color: $lightgray;
        width: 100%;
      }
      &__strong {
        margin-top: 20px;
        font-size: 22px;
        color: $darkgray;
        font-weight: bold;
        line-height: 40px;
        width: 100%;
      }
      &__mfc {
        color: $red;
        font-size: 32px;
        font-weight: 600;
      }
      &__text {
        margin-top: 5px;
        font-size: 22px;
        color: $lightgray;
        width: 100%;
      }
      &__connexion {
        margin-top: 10px;
      }
    }
  }
}

// mobile
@media (max-width: 767px) {

  .home-background {
    &__red {
      display: none;
    }
  }

  .home-container {
    flex-wrap: wrap;
    position: initial;
    margin-bottom: 30px;

    &--isMobile {
      display: flex;
      flex-direction: column;
    }
    &--isNotMobile {
      display: none;
    }

    &__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      background: #FFFFFF;
      //padding-top: 10px;
      img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 260px;
      }
    }

    &__blue {
      &--mobile {
        position: absolute;
        top: 0;
        left: 0;
        width:150px;
        height: 220px;
        background: $blue;
        z-index: 2;
      }
    }

    &__img {
      width: 100%;
      z-index: 14;
      padding-top: 20px;
      padding-bottom: 15px;

      &__red {
        background: $red;
        position: absolute;
        width: 100%;
        z-index: -1;
        top: 66px;
        height: 25%;
        left: 0;
      }
      &__white {
        background: #FFFFFF;
      }
    }

    &__connect {
      display: flex;
      justify-content: center;
      width: 100%;
      background: #FFF;
      text-align: center;

      &__large {
        &__logo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: auto;
          img {
            width: 100%;
            max-width: 260px;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: auto;
          }

        }

        &__not-me {
          padding-left: 20px;
          padding-right: 20px;
        }
        &__title {
          font-size: 18px;
          margin-top: 20px;
        }
        &__sub-title {
          font-size: 17px;
        }
        &__strong {
          margin-top: 0;
          font-size: 32px;
          padding-left: 20px;
          padding-right: 20px;
        }
        &__text {
          font-size: 15px;
          margin-top: 5px;
          color: $lightgray;
          width: 100%;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  text-shadow: 0 -1px 0 #354C8C;
  margin-bottom: 30px;
}
/*.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}*/

.loginBtn--avatar {
  position: absolute;
  height: 38px;
  width: 38px;
  left: -5px;
  top: -5px;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}
/* Shared */
.loginBtn {
  padding: 6px 15px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;

  svg {
    font-size: 22px;
    margin-right: 8px;
  }

  @media screen and (min-width: 768px) {
    margin-right: 20px;
  }
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__modal {
    width: calc(60% - 100px)!important;
    max-width: 1000px;
    border-radius: 20px!important;
    margin:auto!important;

    .ant-modal-footer {
      display: none;
    }
  }
  &__left {
    width: 100%;
    height: 45vh;
    max-height: 45vh;
    color: #FFFFFF;
    background: url('../../../view/image/poings.jpg') no-repeat;

    &__connected {
      @media screen and (max-width: 768px) {
        height: 20vh;
        transition: all .1s ease-in-out;
      }
    }
  }
  &__right {
    width: 100%;
    min-height: calc(55vh + 20px);
    margin-top: -20px;
    padding: 20px 20px 20px 20px;
    background: #FFFFFF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #000000;
    justify-content: center;
    text-align: center;

    &__help {
      color: #FC5E4A;
      margin-top: 20px;
      font-size: 16px;
    }
    &__connected {
      min-height: calc(80vh + 20px);
      transition: all .1s ease-in-out;
    }
    &__title {
     display: none;
    }
    &__logo {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
        max-width: 300px;
      }
    }
    &__connect-sentence {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 22px;
      line-height: 150%;
      font-weight: bold;
    }
    &__connect-sub {
      margin-top: 10px;
    }
    &__facebook {
      margin-top: 20px;
      color: #000000;
      text-decoration: underline;

      &:focus,
      &:focus {
        color: #000000;
      }

      @media screen and (max-width: 768px) {
        // display: none;
      }
    }
    &__button {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 10px;
      min-width: 0;

      &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #FFFFFF;
        font-size: 20px;

        &__yes {
          border-radius: 50px;
          background: #FC5E4A;
          height: 50px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          cursor: pointer;
          transition: all .3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: all .3s ease-in-out;
          }
        }
        &__no {
          border-radius: 50px;
          background: #434343;
          height: 50px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          margin-top: 15px;
          cursor: pointer;
          transition: all .3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: all .3s ease-in-out;
          }
        }
      }

      &__confirmation {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #4c4c4c;
      }
      &__resume {
        border-radius: 20px;
        background: #FFEEEC;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        min-width: 300px;

        &__avatar {
          height: 50px;
          width: 50px;

          img {
            border-radius: 50px;
          }
        }
        &__user {
          margin-top: 10px;
          font-size: 18px;
          font-weight: bold;
        }
      }

      &__not-me {
        padding-top: 30px;
        width: 100%;
        a {
          position: relative;
          width: 100%;
        }
      }

      &__fb {
        max-width: 100%;
        background-color: #4C69BA;
        border: none;
        white-space: nowrap;
        font-size: 18px;
        color: #FFF;
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
        height: 46px;
        padding-left: 20px;

        span {
          max-width: 350px;
          text-overflow: ellipsis;
          overflow: hidden;
          flex-shrink: 0;
        }

        svg {
          font-size: 30px;
          margin-right: 15px;
        }

        &__avatar {
          min-width: 46px;
          width: 46px;
          height: 46px;
          min-height: 46px;
          margin-right: 8px;
          margin-left: -7px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    &__left {
      width: 100%;
      height: 100%;
      min-height: 100vh;
    }
    &__right {

      &__container {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        align-items: center;

        &--modal {
          width: 100%;
          margin: 0;
          max-width: 100%;
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        display: flex;
        font-size: 18px;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 20px;
      }

      &__logo {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        img {
          width: 100%;
          max-width: 300px;
        }
      }
      &__connect-sentence {

      }
      &__connect-sub {
        margin-top: 30px;
      }
      &__button {
        margin-top: 30px;
      }
    }
  }
}