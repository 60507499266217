.counter-data {
  display: flex;
  color: #333;
  font-size: 20px;
  margin-bottom: 5px;

  &__mfc {
    font-weight: bold;
  }
  &__value {
    margin-left: 5px;
    font-weight: bold;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #FFF;
    min-height: 24px;
    border-radius: 24px;
    background:#fe5146;
    padding-left: 10px;
    padding-right: 10px;
  }
}