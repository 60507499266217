.isoLayoutContent {
  border: none!important;
}
.ant-input-suffix {
  // display: none!important;
}
@media screen and (max-width: 550px) {
  .isoLayoutContent {
    padding: 0!important;
    border: none!important;
  }
  .dashboard__table__header {
    display: none;
  }
  .admin-row-flex--padding {
    padding: 5px!important;
  }
}
.admin-datepicker {
  min-width: 120px!important;
  max-width: 150px!important;
  @media screen and (max-width: 550px) {
    min-width: 100%!important;
    max-width: 100%!important;
  }
}
.admin-col {
  display: flex;
  width: 100%;
  padding: 5px;

  @media screen and (max-width: 550px) {
   flex-direction: column;
    padding: 0;
  }

  .ant-row ant-form-item {
    width: 100%;
  }
  input {
    width: 100%;
  }
}
.admin-row {
  width: 100%;
  display: flex;
  flex-direction: column;

  &--small {
    width: 100%;
    display: flex;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }
  }
  &-flex {
    width: 100%;
    display: flex;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      padding: 0;
    }
    .admin-col {
      margin-bottom: 5px;
    }

    &--padding {
      span {
        padding-left: 15px;
        font-weight: normal;
        b {
          padding-left: 5px;
          font-weight: bold;
        }
      }
    }
  }
}
.edit {
  width: 100%;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.65);

  &__title {
    background: #2c3447;
    color: #FFF;
    border-bottom: solid 1px rgba(241, 243, 246, 0.15);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    font-size: 18px;

    &__left {
      display: flex;
      padding: 8px;
      color: rgba(255, 255, 255, 0.65);
      background: #2c3447;
      width: 200px;
      justify-content: flex-end;
      padding-right: 15px;
      border-bottom: solid 1px rgba(241, 243, 246, 0.15);
    }
    &__right {
      display: flex;
      padding: 8px;
      padding-left: 15px;
      border-bottom: solid 1px rgba(43, 52, 71, 0.12);
      width: 100%;

      &__container {
        width: 100%;
        &__rows {
          display: flex;
          flex-direction: column;
          width: 100%;
          &:nth-child(odd) {
            background: rgba(43, 52, 71, 0.03137254901960784);
          }
          &:nth-child(even) {
            background: #FFF;
          }

          &__row {
            display: flex;
            flex-direction: row;
            width: 100%;

            &__label {
              font-weight: bold;
              font-size: 14px;
              padding-right: 15px;
              //display: flex;
              //justify-content: flex-end;
              width: 100px;
            }
            &__value {
              font-size: 14px;
              width: calc(100% - 100px)
            }

            &__align {
              display: flex;
              width: 100%;
              align-items: center;

              &__left {
                width: 20%;
                display: flex;
                justify-content: flex-end;
                padding-right: 15px;
              }
              &__right {
                width: 100%;
                max-width: 200px;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
