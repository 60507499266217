.complements {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: #3a549e;
  display: flex;
  align-items:center;
  color: #FFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;

  &__left {
    width: 20%;
    display: flex;
    justify-content: center;

    img {
      height: 55px;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }
  &__center {
    width: 60%;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
  }
  &__right {
    width: 20%;
    display: flex;
    justify-content: center;

    svg {
      font-size: 18px;
    }
  }
}

// Bigger than mobile
@media screen and (min-width: 840px) {
  .complements {
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    left: auto;
    position: fixed;
    right: 40px;
    background: #3a549e;
    display: flex;
    align-items: center;
    color: #FFF;
    bottom: 40px;
    z-index: 30;
    &__left {
      img {
        height: 80px;
        margin-bottom: 35px;
        margin-left: 0;
        margin-right: -120px;
      }
    }
    &__center,
    &__right {
      display: none;
    }
  }
}