@import '../containers/colors';

.square-pack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    background: #FFEEEC;
    color: $red;
    min-height: 150px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 440px) {
    flex-direction: column;
    justify-content: space-between;
  }

    a {
      color: $red!important;
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 40px;
      color: $red;
      font-size: 42px;

      @media screen and (max-width: 440px) {
        font-size: 20px;
        padding-top: 20px;
      }

    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      // width: calc(100% - 50px);
      width: 100%;
      min-height: 120px;
      font-size: 16px;
      color: $red;
      padding-left: 10px;
      padding-right: 10px;

      padding-top: 15px;
      padding-bottom: 15px;

      @media screen and (max-width: 440px) {
        text-align: center;
        font-size: 13px;
        justify-content: flex-start;

        &--big {
          font-size: 16px;
          padding-top: 24px;
        }

        &__facebook {
          font-weight: bold;
          color: $red;
          font-size: 18px;
        }

        &--hide {
          display: none;
        }

        &__instructions {
          width: 100%;

          &--bold {
            font-weight: 600;
            line-height: 19px;
          }
        }
      }

      @media screen and (max-width: 375px) {
        text-align: center;
        font-size: 12px;
        justify-content: flex-start;

        &--big {
          font-size: 14px;
          padding-top: 24px;
          padding-left: 5px;
          padding-right: 5px;
        }

        &__facebook {
          font-weight: bold;
          color: $red;
          font-size: 18px;
        }

        &--hide {
          display: none;
        }

        &__instructions {
          width: 100%;

          &--bold {
            font-weight: 600;
            line-height: 19px;
          }
        }
      }

      &__facebook {
        font-weight: bold;
        color: $red;
        width: 100%;
        font-size: 18px;
      }

      &__instructions {
        width: 100%;
        &--bold {
          font-weight: 600;
          line-height: 19px;
        }
      }
    }

  &__icon {
    display: flex;
    // position: absolute;
    margin-top: -25px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50px;
    background: #FF6734;
    color: #FFFFFF;
    height: 50px;
    width: 50px;
    font-size: 24px;

    &--blue {
      background: #3A549E;
    }
  }
  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #222222;
    font-size: 13px;
    font-style: italic;
    text-align: center;
    justify-content: center;
    min-height: 120px;

    &__instructions {
      margin-top: 5px;
      padding: 5px;
      &--bold {
        font-weight: 600;
      }
    }
    span {
      font-weight: bold;
    }

    &__step {
      font-weight: bold;
      font-size: 17px;
      font-style: italic;
    }
  }
  &__footer {
    margin-top: 5px;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20px;
    color: #FFFFFF;
    background: #FF6734;
    text-align: center;
    &--blue {
      background: #3A549E;
    }

    span {
      font-weight: bold;
      font-size: 15px;
    }
  }
}
.instructions {

  &__disclaimer {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  &__pack-info {
    margin-bottom: 30px;
    text-align: right;
    padding-right: 10px;
    text-decoration: underline;

    @media screen and (max-width: 840px) {
      text-align: center;
    }

    a {
      color: $red;
      &:focus,
      &:hover {
        color: $red;
      }
    }

  }
  &__packs {
    display: flex;
    width: 100%;
    justify-content: center;

    flex-direction: row;
    align-items: center;


    &__material {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 20px;
      color: $red;
      text-decoration: underline;
    }

    &__pack {
      padding: 10px;
      width: 100%;
      &:last-child {
        //margin-top: 15px;
      }

      img {
        width: 100%;
      }
    }
  }
  &__title {
    font-size: 19px;
    color:#000;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;

    &__sub {
      color: $lightgray;
    }
  }

  &__material {
    display: flex;
    width: 100%;
    align-items: center;

    &__img {
      width: 40px;
    }

    &__link {
      width: calc(100% - 40px);
      color: $red;
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  &__todo {
    display: flex;
    min-height: 30px;
    height: 100%;
    width: 100%;
    align-items: flex-start;


    &__checkbox {
      display: flex;
      width: 30px;
      color: $red;
      font-size: 18px;
      align-items: flex-start;
      margin-top: 3px;
    }
    &__text {
      display: flex;
      width: calc(100% - 30px);
      font-size: 15px;
      align-items: flex-start;
      color:#000;
    }
  }
  &__join {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 15px;
    color:#000;
    font-size: 16px;
    font-weight: 500;
    border:solid 2px $red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover {
      background: $red;
      color: #FFF;
      transition: all .2s ease-in;
    }
  }
}

@media screen and (min-width: 840px) {

  .instructions__packs {
    display: flex;
    width: 100%;
    justify-content: center;

    flex-direction: row!important;
    align-items: center;


    &__material {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 20px;
      color: $red;
      text-decoration: underline;
    }

    &__pack {
      width: 50%;
      // padding: 5px;
      margin-top: 0!important;

      img {
        width: 100%;
      }
    }
  }
}