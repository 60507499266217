.test {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  &__content{
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    iframe {
      border: none;
      border-radius: 8px;
      background: #000000;
    }
  }
}