@import '../../containers/colors';
.warning__modal {
  padding: 15px;

  @media screen and (max-width: 840px) {
    padding: 0;
  }
  color: #000000;
  .ant-modal-footer {
    display: none;
  }
  &__first {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-top: 20px;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 840px) {
      margin: 0;
      font-size: 16px;
    }
  }
  &__second {
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    @media screen and (max-width: 840px) {
      font-size: 20px;
    }
  }
  &__button__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;

    &__button {
      width: calc(100% - 30px);
      background: $red;
      color: #FFFFFF;
      border-radius: 15px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        transform: scale(1.05);
        transition: all .3s ease-in-out;
      }

      &:first-child {
        margin-right: 15px;
        background: #FFE4E0;
        color: $red;
      }

      &__label {
        font-weight: bold;
        font-size: 18px;
      }
      &__text {
        font-size: 16px;
      }
    }
  }
}
.payment {
  margin-top: 0;

  &__description {
    display: none;
  }
  &__security-label {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    color:$greenInfo;
    font-weight: 600;
    font-size: 18px;
    font-style: italic;
    text-align: center;
  }

  &-container {
    width: 100%;
    max-width: 500px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 15px;
    position: relative;

    &__form {
      width: 100%;
      max-width: 500px;
      z-index: 100;
      display: flex;
      flex-direction: column;
      margin: auto;
      position: relative;
      padding: 0;

      &__details {
        display: flex;
        width: 100%;
        flex-direction: column;
        border: solid 1px #d9d9d9;
        margin-bottom: 15px;
        margin-top: 30px;

        &__title {
          width: 100%;
          border-bottom: solid 1px #d9d9d9;
          background: $extrraLightGray;
          color: $gray;
          padding: 5px;
          font-style: italic;
        }

        &__container {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: solid 1px #d9d9d9;

          &__article {
            width: 100%;
            padding-left: 5px;
          }
          &__price {
            font-weight: bold;
            min-width: 50px;
            padding-right: 5px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
          }
        }
        &__total {
          width: 100%;
          display: flex;
          padding-right: 5px;
          font-weight: bold;
          justify-content: flex-end;
        }
      }
      &--mask {
        opacity: 0;
        visibility: hidden;

        position: absolute;
        z-index: 10;
        background: rgba(255, 255, 255, 0.59);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        cursor: progress;
        transition: opacity .2s ease-in-out;

        &--visible {
          opacity: 1;
          visibility: visible;
          transition: opacity .2s ease-in-out;
        }
      }
    }
  }

  .payment-form {
    background: white;
    padding: 15px;
    border: solid 1px #e2e2e2;
  }
  .cards-img {
    max-width: 100px;
  }
  .cards-part {
    border-top: solid 1px #e2e2e2;
    padding-top:10px;
    margin-top: 10px;
  }
  .cgu-info {
    font-size: 12px;
    text-align: left;
  }
  &--title {
    font-size:16px;
    border-bottom: solid 1px #e2e2e2;
    padding: 15px;
    padding-top: 5px;
    margin-bottom: 15px;
  }
  .success-color {
    color: green;
  }
  .error-color {
    color: red;
  }
}
.card-num-container {
  width: 100%;
  border: solid 1px #d9d9d9;
  border-radius: 3px;
  padding: 6px;
  padding-left: 12px;
  padding-right: 10px;
  min-height: 30px;
  font-size: 12px;
}

@media screen and (max-width: 380px) {
  .custom-ant-input {
    height: 30px!important;
  }
  .card-num-container {
    padding-top: 5px!important;
    padding-bottom: 5px!important;
  }
}

@media screen and (min-width: 840px) {
  .custom-ant-input {
    height: 30px;
  }

  .instructions {
    margin-bottom: 50px;
  }
  .payment{
    &__description {
      display: block;

      font-size: 16px;
      width: 100%;
      max-width: 500px;
      margin: auto;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 15px;

      span {
        color: $red;
        padding-left: 5px;
        font-weight: 600;
      }
    }
    &-container {
      width: 100%;
      max-width: 500px;
      z-index: 100;
      display: flex;
      padding: 0;
      flex-direction: column;
      margin: auto;
      position: relative;

      &__form {
        padding: 15px;
      }
    }
  }
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 0!important;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  display: none!important;
}
.ant-modal-confirm-title {
  color: $red!important;
  font-size: 18px!important;
  margin-bottom: 15px!important;
  text-align: center;
}
.checkout__modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__root {
    .ant-modal-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .ant-modal-footer {
      button {
        border-radius: 30px!important;
      }
    }
  }

  &__bold {
    font-weight: bold;
  }
  &__close {
    display: flex;
    cursor: pointer;
    height: 40px;
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end;
    text-decoration: underline;
    align-items: center;
  }

  &__button {
    cursor: pointer;
    background: $red;
    color: #FFF;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 30px;
    transition: all .3s ease-in-out;

    &--is-pack {
      background: $green;
      transition: all .3s ease-in-out;
    }
  }

  &__half {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__plus {
      font-size: 42px;
      position: absolute;
      left: 50%;
      margin-left: -21px;
      margin-top: 40px;
      color: $red;
      opacity: 0;
    }
  }
  &__info {
    padding-bottom: 15px;
    text-align: center;
    font-style: italic;
    color: #888888;
  }
  &__item {
    //border: dashed 5px $red;
    padding: 15px;
    margin-bottom: 15px;

    &--pdf {
      width: 100%;
      cursor: pointer;
      .checkout__modal__item__photo {
        svg {
          font-size: 60px;
          color: $red;
        }
        height: 70px!important;
        max-height: 70px;
      }
    }
    &__small {
      width: 50%;
      margin: auto;

      img {
        max-width: 100%;
      }
    }
    &__photo {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      height: 200px;
      img {
        width: 100%;
        height: auto;
        max-height: 100%;
        margin: auto;
      }
    }
    &__text {
      font-weight: bold;
      font-size: 14px;
      text-align: center;

      &__desc {
        font-weight: 400;
        font-style: italic;
      }
    }
  }
}
.ant-modal-confirm-btns {
  button {
    background: none!important;
    color: #333!important;
    border: 0!important;
    box-shadow: none!important;
    cursor: pointer!important;
    text-decoration: underline!important;
    font-size: 14px !important;
    display: flex;
    justify-content: flex-end;
  }
}
.ant-btn-primary {
  background: $red!important;
  border: solid 1px $red!important;
  border-radius: 0!important;
}
.ant-form-item {
  margin-bottom: 15px!important;
}
.ant-modal-title {
  font-weight: bold!important;
}
