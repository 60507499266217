.app-container {
  padding-top: 170px;
  padding-bottom: 60px;
  width: 100%;
  overflow: hidden;
}

// Bigger than mobile
@media screen and (min-width: 1120px) {
  .app-container {
    width: 100%;
    margin-top: 210px;
    height: calc(100% - 90px);
    padding: 0;
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (min-width: 840px) and (max-width: 1120px)  {
  .app-container {
    width: 100%;
    margin-top: 210px;
    height: calc(100% - 90px);
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}